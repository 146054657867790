import styled from '@emotion/styled'
import mq from 'styles/breakpoints'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
`

export const ShapeContainer = styled.div`
  align-self: center;
  width: 400px;
  height: 400px;
  ${mq['md']} {
    display: none;
  }
`

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-weight: bold;
`

export const FlexRow = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'space-between'};
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;

  input {
    border-radius: 0.5rem;
  }

  ${mq['sm']} {
    flex-direction: column;
    justify-content: center;
  }
`

export const ButtonWrapper = styled.div`
  margin-right: 2em;

  ${mq['sm']} {
    margin-top: 1em;
    margin-bottom: 1em;
  }
`

export const ProfileButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 3rem;
  width: 10.6rem;
  line-height: 1.2rem;
  font-family: Roboto, Arial, sans-serif;
  color: white;
  margin-left: ${(props) => props.marginLeft || '2em'};
  background: #ebbd34;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  outline: none;

  &:hover {
    background: #e6aa05;
  }
`

export const Navigate = styled.button`
  background: none;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  cursor: pointer;

  h1 {
    font-size: 2rem;
  }

  span {
    font-size: 3rem;
    text-align: center;
  }
`

import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { GoCreditCard } from 'react-icons/go'

import ButtonShortText from 'components/common/ButtonShortText'
import LottieShape from 'components/animations/LottieShape'
import LoadingAnimation from 'components/animations/LoadingAnimation'

const LoadingContainer = styled.div`
  margin-left: -12px;
  margin-right: 7px;
`

const BillingPortalSessionButton = ({
  onClickGetBillingPortalSession,
  isLoading,
}) => {
  const { t } = useTranslation('billingPortalSessionButton')
  return (
    <ButtonShortText
      text={t('subscription')}
      isLoading={isLoading}
      onClick={() => onClickGetBillingPortalSession()}
      element={
        isLoading ? (
          <LoadingContainer>
            <LottieShape animationData={LoadingAnimation} size={48} />
          </LoadingContainer>
        ) : (
          <GoCreditCard size="1.4em" style={{ marginRight: '1.35em' }} />
        )
      }
    />
  )
}

BillingPortalSessionButton.defaultProps = {
  isLoading: false,
}

BillingPortalSessionButton.propTypes = {
  onClickGetBillingPortalSession: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
}

export default BillingPortalSessionButton

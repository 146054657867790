import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const ButtonWrapperShort = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  height: 3rem;
  width: 10.6rem;
  font-family: Roboto, Arial, sans-serif;
  color: white;
  margin-left: 2em;
  padding: 0 1em;
  background: #ebbd34;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  outline: none;

  &:hover {
    background: #e6aa05;
  }
`

const ButtonShortText = ({ text, onClick, element, isLoading }) => {
  return (
    <ButtonWrapperShort onClick={onClick} disabled={isLoading}>
      {element}
      {text}
    </ButtonWrapperShort>
  )
}

ButtonShortText.defaultProps = {
  onClick: null,
  element: null,
  isLoading: null,
}

ButtonShortText.propTypes = {
  onClick: PropTypes.func,
}

export default ButtonShortText

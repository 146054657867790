import React, { useContext, useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Shape from 'shape-library'
import { navigate, graphql } from 'gatsby'
import { useLazyQuery } from '@apollo/client'
import Layout from 'components/layout'

import BillingPortalSessionButton from 'components/BillingPortal/BillingPortalSessionButton'
import UserContext from 'context/user/UserContext'
import LottieShape from 'components/animations/LottieShape'
import LoadingAnimation from 'components/animations/LoadingAnimation'
import { isMobilePlatform } from 'components/common/DeviceOS'
import SEO from 'components/seo'
import { GET_BILLING_PORTAL_SESSION } from 'services/graphql'

import {
  Container,
  ShapeContainer,
  MenuContainer,
  ButtonWrapper,
  ProfileButton,
  FlexRow,
  Navigate,
} from 'components/manageAccountComponents'

const ManageAccount = ({ data, location }) => {
  const { t } = useTranslation('manageAccount')
  const siteTitle = data.site.siteMetadata.title
  const { user, isAuthInitialized, updateUserProfile } = useContext(UserContext)
  const [inputDisplayName, setInputDisplayName] = useState('')
  const [updatingProfile, setUpdatingProfile] = useState(false)
  const PRICING_PATH = '/#pricing'

  useEffect(() => {
    if (user && user.displayName && user.displayName !== '') {
      setInputDisplayName(user.displayName)
    }
  }, [user])

  const handleInputChange = useCallback(
    (e) => setInputDisplayName(e.currentTarget.value),
    [setInputDisplayName],
  )

  const updateProfile = useCallback(
    async (event) => {
      event.preventDefault()
      setUpdatingProfile(true)
      updateUserProfile(inputDisplayName).then(() => {
        setUpdatingProfile(false)
      })
    },
    [inputDisplayName, updateUserProfile, setUpdatingProfile],
  )

  const [
    getBillingPortalSession,
    {
      data: getBillingPortalSessionData,
      loading: getBillingPortalSessionLoading,
      error: getBillingPortalSessionError,
    },
  ] = useLazyQuery(GET_BILLING_PORTAL_SESSION)

  const onClickGetBillingPortalSession = async () => {
    if (user && user.uid && !getBillingPortalSessionLoading) {
      await getBillingPortalSession()
    }
  }

  useEffect(() => {
    if (getBillingPortalSessionError) {
      navigate(PRICING_PATH)
    }
  }, [getBillingPortalSessionError])

  // redirect the user to Stripe Billing Portal Session
  useEffect(() => {
    if (typeof window !== 'undefined' && getBillingPortalSessionData) {
      window.location.assign(
        getBillingPortalSessionData.getBillingPortalSession,
      )
    }
  }, [getBillingPortalSessionData])

  useEffect(() => {
    if (typeof window !== 'undefined' && isAuthInitialized && !user) {
      navigate(process.env.GATSBY_SITE_SIGN_IN_PATH)
    }
  }, [user, isAuthInitialized])

  return (
    <>
      <Layout location={location} title={siteTitle}>
        <SEO title={t('title')} />
        <Navigate
          onClick={() => {
            navigate(process.env.GATSBY_SITE_ACCOUNT_PATH)
          }}
        >
          <h1>
            <span>{t('backArrow')}</span> {t('backText')}
          </h1>
        </Navigate>
        <Container>
          {!isAuthInitialized ? (
            <LottieShape animationData={LoadingAnimation} size={150} />
          ) : null}
          {isAuthInitialized && user && (
            <>
              <ShapeContainer>
                <Shape
                  type="animatedillustrations"
                  category="Abstract"
                  name="Settings"
                  primaryColor="#4a90e2"
                  secondaryColor="#cacaca"
                  size={400}
                />
              </ShapeContainer>
              <MenuContainer>
                <h2>{t('accountSettingsHeading')}</h2>
                {!isMobilePlatform() && (
                  <FlexRow>
                    {t('manageSubscriptionLabel')}
                    <ButtonWrapper>
                      <BillingPortalSessionButton
                        onClickGetBillingPortalSession={
                          onClickGetBillingPortalSession
                        }
                        isLoading={getBillingPortalSessionLoading}
                      />
                    </ButtonWrapper>
                  </FlexRow>
                )}
                <FlexRow justifyContent="center">
                  {t('profileSettingsHeading')}
                </FlexRow>

                <form onSubmit={updateProfile}>
                  <FlexRow>
                    <input
                      type="text"
                      name="username"
                      value={inputDisplayName}
                      onChange={handleInputChange}
                    />
                    <ButtonWrapper>
                      <label htmlFor="Display Name">
                        <ProfileButton id="Display Name" type="submit">
                          {!updatingProfile ? (
                            t('updateDisplayNameButton')
                          ) : (
                            <LottieShape
                              animationData={LoadingAnimation}
                              size={48}
                            ></LottieShape>
                          )}
                        </ProfileButton>
                      </label>
                    </ButtonWrapper>
                  </FlexRow>
                </form>
              </MenuContainer>
            </>
          )}
        </Container>
      </Layout>
    </>
  )
}

export default ManageAccount

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
